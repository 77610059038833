<template>
  <div class="legals">
    <b-container class="legals-content text-center">
      <h2>Código de ética</h2>

      <b-row class="d-flex justify-content-center">
        <b-col md="12" class="information mt-5" style="text-align: justify">
          <h4>
            CONFIANZA EN NUESTRAS RELACIONES CUMPLIMIENTO A LA LEY

            <p>
              Respetamos y damos cumplimiento a la Ley, Reglamentos y
              disposiciones regulatorias, así como a políticas internas
              establecidas. Mostrar respeto por las normas, principios y
              valores, es una práctica de todos los días a través del ejemplo.
            </p>
          </h4>

          <br />

          <h4>
            CONFIDENCIALIDAD DE LA INFORMACIÓN (CLIENTES Y COLABORADORES)

            <p>
              Cumplimos nuestros compromisos basándonos en la confianza
              mostrando nuestra integridad y que se puede confiar en nosotros.
              Mantenemos y protegemos la información confidencial que nos fue
              confiada y el uso de datos personales de clientes, colaboradores y
              proveedores de acuerdo con la normatividad.
            </p>
          </h4>

          <h4>
            INFORMACIÓN PRIVILEGIADA Y CONFIDENCIAL

            <p>
              Cumplimos con las leyes de protección de datos personales y de
              privacidad, no filtramos ni divulgamos información confidencial ni
              en familia, amigos y redes sociales, relacionada con la actividad
              empresarial que no sea pública de Bee Telecom.
            </p>
          </h4>

          <br />

          <h4>
            COMPETENCIA

            <p>
              En Bee Telecom estamos comprometidos con el cumplimiento a la Ley
              Federal de Competencia y evitar prácticas monopólicas y/o
              concentraciones ilícitas. Está estrictamente prohibido hablar o
              tratar asuntos relacionados con la actividad comercial
              confidencial de la empresa, como costos, proveedores (en algunos
              casos), equipos, condiciones de venta, planes de comercialización,
              descuentos, información de desarrollo de productos y cualquier
              otro tipo de situación que vulnere la posición de la Bee Telecom
              frente a sus competidores. Queda prohibida la obtención o la
              requisición de información de otras empresas competencia de Bee
              Telecom, sobre sus clientes, proveedores o condiciones de
              servicios cuando ésta se encuentre restringida legalmente como:
              Que la contratación de nuevos colaboradores se vea condicionada
              para obtener información confidencial de su antiguo empleador y
              comprometa su divulgación. La obtención de información sea a
              través de sobornos.
            </p>
          </h4>

          <h4>
            CONFLICTO DE INTERÉS

            <p>
              Declaramos cualquier posible conflicto de interés al establecer
              una relación entre clientes, proveedores y/o colaboradores en la
              que pueda verse comprometida nuestra objetividad. Cualquier
              situación de dicha índole deberá ser informada por escrito al
              superior inmediato o a la Coordinación de Recursos Humanos. Sin
              excepción todos los colaboradores responsables de realizar compras
              y que interactúen directa o indirectamente con otros proveedores y
              empresas, así como organismos autónomos y gubernamentales deben
              apegarse a la Política de Conflicto de Interés y evitar conductas
              que puedan ser interpretadas como tal o generen privilegios o
              preferencias.
            </p>
          </h4>

          <br />

          <h4>
            INNOVACIÓN TECNOLÓGICA

            <p>
              Trabajamos con la más alta tecnología en redes, realizamos nuestro
              propio diseño, el software y las aplicaciones necesarias con lo
              que logramos estar a la vanguardia mejorando la calidad en
              servicio permitiéndonos ser más competitivos en el mercado.
            </p>
          </h4>

          <br />

          <h3>CONDUCTA FRENTE A LA FAMILIA Bee Telecom</h3>

          <br />

          <h4>
            DERECHOS HUMANOS

            <p>
              No aceptamos realizar negocios con ninguna otra empresa o persona
              que tenga prácticas de explotación, trabajo forzoso y/u
              obligatorio infantil; castigos físicos, cualquier forma de abuso
              humano, discriminación y trabajo forzado.
            </p>
          </h4>

          <br />

          <h4>
            RESPETO A LOS INDIVIDUOS Y A LA DIVERSIDAD.

            <p>
              Respetamos el derecho de nuestros empleados de estar debidamente
              representados y formar libremente organizaciones de trabajadores o
              sindicatos, o unirse a ellos, sin ningún tipo de represalia,
              intimidación o acoso.
            </p>
          </h4>

          <br />

          <h3>RESPETO A LOS INDIVIDUOS Y A LA DIVERSIDAD.</h3>

          <br />

          <h4>
            NO DISCRIMINACIÓN

            <p>
              En Bee Telecom respetamos la diversidad y el derecho de igualdad
              de oportunidades. Estamos comprometidos con la contratación y el
              crecimiento profesional de nuestros colaboradores, en función de
              su capacidad, experiencia y desempeño sin distinción de raza,
              color, sexo, idioma, religión, nacionalidad, opinión política,
              orientación sexual o condición social; trabajamos en la prevención
              de cualquier forma de acoso o abuso físico, verbal, sexual, o
              psicológico, así como amenazas e intimidación en el trabajo.
            </p>
          </h4>

          <br />

          <h4>
            PROTECCIÓN DE LA SALUD Y SEGURIDAD LABORAL

            <p>
              Ofrecemos un lugar de trabajo saludable y seguro con el fin de
              minimizar el riesgo de accidentes o lesiones. Promovemos la
              concientización sobre el riesgo y fomentamos el comportamiento
              responsable. Garantizamos el derecho a la asistencia sanitaria
              preventiva y a la atención médica, y nos esforzamos por mejorar de
              manera continua el desempeño de todos en materia de salud
              ocupacional.
            </p>
          </h4>

          <br />

          <h4>
            AMBIENTE LIBRE DE VIOLENCIA Y HOSTIGAMIENTO

            <p>
              No estamos a favor de la agresión como medio de comunicación, por
              lo que, en busca de una calidad de vida laboral dentro de la
              empresa, buscamos proveer un ambiente libre de violencia física,
              psicológica o sexual. No toleramos el uso de vocabulario
              inapropiado, obsceno, humillante, amenazante, ofensivo, empleo de
              señales y expresiones obscenas, episodios de cólera, agresiones
              físicas o sexuales, acoso laboral o sexual entre colaboradores,
              clientes y proveedores; sin importar el puesto o jerarquía.
            </p>
          </h4>

          <br />

          <h4>
            USO DE ALCOHOL Y DROGAS

            <p>
              Para mantener un ambiente seguro, está estrictamente prohibido que
              alguno de nuestros colaboradores trabajen bajo el efecto de
              bebidas alcohólicas, drogas o alguna otra sustancia ilegal, así
              como la distribución de alcohol y drogas dentro de la empresa o en
              las instalaciones de los clientes y/o proveedores.
            </p>
          </h4>

          <br />

          <h4>
            CERO TOLERANCIA A LA CORRUPCIÓN

            <p>
              La lucha contra la corrupción es una cuestión prioritaria e
              inaplazable, por esa razón en Bee Telecom tenemos CERO tolerancia
              a cualquier tipo de corrupción o fraude, lo cual significa que
              algún incidente relacionado a esto se investigará a fondo y se
              realizarán las sanciones pertinentes.
            </p>
          </h4>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Codigo",

  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style scoped>
.legals {
  background: #fecd00;
}

.legals .legals-content {
  padding-top: 150px;
  padding-bottom: 150px;
}

.legals .legals-content h2 {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 40px;
  text-shadow: 1px 1px black, -1px -1px #ffffff;
}

.legals .legals-content h3 {
  font-weight: 600;
  font-size: 25px;
  text-align: center;
  color: #ffffff;
}

.legals .legals-content h4 {
  font-weight: 600;
  font-size: 22.5px;
}

.legals .legals-content h4 p {
  font-weight: 400;
  font-size: 15px;
}
</style>
